.no-order {
  padding: 10px 14px !important;
  background-color: #fafafa !important;
  border-radius: 8px;
}

.table-custom {
  overflow: visible !important;
}

.table-custom tbody tr td .form-control {
  box-shadow: none !important;
}

table {
  overflow: visible;
}

.table-custom tbody .invalidTime {
  border: 1px solid red !important;
}

table tbody tr td .css-13cymwt-control,
table tbody tr td .css-t3ipsp-control {
  height: 100%;
  width: 100%;
  padding: 6px 0px;
  min-width: 130px;
  border: none !important;
  box-shadow: none !important;
  border-top-right-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 8px;
}

table tbody tr td .css-b62m3t-container {
  border-right: 1px solid #e8ecef;
}

table tbody tr td .tool-tip {
  top: 16px;
  right: 14px;
  color: #013871;
  opacity: 1;
}

table tbody tr td .react-tooltip {
  max-width: 300px;
  z-index: 900;
}

table tbody tr td .css--menu {
  z-index: 500;
}

.custom-search-group-create {
  position: relative;
}

.hover:hover {
  cursor: pointer;
}

.custom-search-group-create .search-wrapper {
  position: absolute;
  z-index: 500;
  left: 0px;
  top: 50px;
  border-width: 0px 1px 1px;
  border-top-style: initial;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: initial;
  border-right-color: #e8ecef;
  border-bottom-color: #e8ecef;
  border-left-color: #e8ecef;
  border-image: initial;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: rgb(250, 250, 250);
  width: 100%;
  max-height: 175px;
  overflow-y: auto;
}

.custom-search-group-create .search-wrapper>.search-input {
  padding: 4px 10px !important;
  position: sticky;
  margin: 4px;
  width: calc(100% - 8px) !important;
  top: 4px;
}

.custom-search-group-create .search-wrapper .item-card {
  width: 100%;
  padding: 5px;
}

.custom-search-group-create .search-wrapper .item-card-hover:hover {
  background-color: #004696;
  color: white;
  cursor: pointer;
}

.react-datepicker-wrapper input {
  background: url("../../assets/img/icon-calendar.svg") no-repeat right;
  background-size: 37px;
  background-origin: content-box;
  padding-right: 10px;
  background-color: #fff;
}

.orderDetails .costcenter .form-check {
  min-height: 1.5rem;
  padding-left: 0 !important;
  margin-bottom: .125rem;
}

.orderDetails .costcenter input[type='radio'] {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}

.orderDetails .costcenter input[type='radio']:after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}

.orderDetails .costcenter input[type='radio']:checked:after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #000000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}