.overview-section .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.print-fragment .label-section {
    display: none;
    visibility: hidden;
}

.print-overview-section {
    display: none;
    visibility: hidden;
}

@media print {
    .overview-section {
        display: none;
        visibility: hidden;
    }

    .print-overview-section {
        display: block;
        visibility: visible;
        font-size: 11px !important;
    }

    .print-overview-section .prod-card {
        padding: 10px 25px;
    }

    .print-fragment {
        padding: 0 1rem;
    }

    .print-fragment .input-section,
    .print-fragment .overview-group {
        height: 0 !important;
    }

    .print-fragment .label-section {
        display: block;
        visibility: visible;
    }

    .print-fragment .loading-screen,
    .print-fragment .input-section,
    .print-fragment .overview-group,
    .print-fragment .edit-btn {
        visibility: hidden;
    }

    .print-fragment .prod-card {
        border: 1px solid gray;
    }
}