.loading-screen {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  bottom: 0;
}
.loading-screen-logo {
  width: 474px !important;
  height: 84px;
}
.loading-animation {
  width: 474px;
}

.loader-span {
  width: 100% !important;
}

@media only screen and (max-width: 480px) {
  .loading-screen-logo {
    width: 225px !important;
    height: 40px;
  }
  .loading-animation {
    width: 225px;
  }
}
